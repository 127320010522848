import DataGrid, { Column, Editing, Toolbar, Item, Form, Popup as PopupGrid, RequiredRule, TotalItem, Summary, Format } from 'devextreme-react/data-grid';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Item as FormItem } from 'devextreme-react/form';
import mainStore from './store/mainStore';

const DocItem = (props) => {
    const { doc_id } = props.data.data;

    useEffect(() => {
        (
            async () => {
                await mainStore.getDocItemSource(doc_id);
            }
        )();
    }, [doc_id]);

    const saveData = async (e) => {
        const arr = e.changes.map(el => {
            if (el.type === "insert")
                delete el.data.item_id;
            if (el.data)
                el.data.doc_id = doc_id;
            return el;
        });

        await mainStore.updateDocItem(arr);
    }

    return (
        <div style={{ fontSize: "15px", fontWeight: 500, margin: "-10px -5px -10px 10px", borderRadius: "4px", boxShadow: "-2px -2px 15px 0px darkgray", background: "linear-gradient(90deg, skyblue, aliceblue)" }}>
            <DataGrid
                width="100%"
                dataSource={mainStore.docItemSource.filter(e => e.doc_id === doc_id)}
                keyExpr="item_id"
                showColumnLines
                showRowLines
                showBorders
                onSaved={saveData}
            >
                <Column dataField="name" caption="Наименование товара" width={350}> <RequiredRule /> </Column>
                <Column dataField="code" caption="Код" width={100} />
                <Column dataField="price" caption="Цена" width={200} dataType="number" > <Format type="currency" /> <RequiredRule /> </Column>
                <Column dataField="cnt" caption="Кол-во" width={110}> <RequiredRule /> </Column>
                <Column dataField="unit_name" caption="Ед.изм" width={100} />
                <Column dataField="unit_code" caption="Код по ОКЕИ" width={120} />
                <Column dataField="pack" caption="Вид упаковки" width={170} />
                <Column dataField="nds" caption="НДС" width={100} />

                <Summary calculateCustomSummary={(e) => {
                    const arr = mainStore.docItemSource.filter(el => el.doc_id === doc_id);

                    switch (e.name) {
                        case "price":                            
                            const sum = arr.reduce((res, obj) => res + (obj.price * obj.cnt), 0);
                            e.totalValue = `Итого: ${new Intl.NumberFormat("ru").format(sum)} ₽`;
                            break;
                        case "cnt":
                            const tot = arr.reduce((res, obj) => res + obj.cnt, 0);
                            e.totalValue = `Всего: ${tot} шт.`;
                            break;
                    }
                }}>
                    <TotalItem column="cnt" summaryType="custom" name="cnt" />
                    <TotalItem column="price" summaryType="custom" name="price" />
                    <TotalItem column="name" summaryType="count" />
                </Summary>

                <Editing
                    mode="popup"
                    allowAdding
                    allowUpdating
                    allowDeleting
                >
                    <PopupGrid
                        title="Товар"
                        showTitle
                        width={450}
                        height={480}
                    />
                    <Form>
                        <FormItem
                            itemType="group"
                            colCount={2}
                            colSpan={2}
                        >
                            <FormItem dataField="name" colSpan={2} />
                            <FormItem dataField="code" />
                            <FormItem dataField="price" />
                            <FormItem dataField="cnt" />
                            <FormItem dataField="unit_name" />
                            <FormItem dataField="unit_code" />
                            <FormItem dataField="pack" />
                            <FormItem dataField="nds" />
                        </FormItem>
                    </Form>
                </Editing>

                <Toolbar>
                    <Item location="before">
                        <div style={{ fontSize: "16px", fontWeight: 500, color: "black" }}> Товары </div>
                    </Item>
                    <Item name="addRowButton" />
                    <Item name="saveButton" />
                    <Item name="revertButton" />
                </Toolbar>
            </DataGrid>
        </div>
    );
}

export default observer(DocItem);