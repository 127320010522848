import { observer } from 'mobx-react';
import FileUploader from 'devextreme-react/file-uploader';
import { useEffect, useState } from 'react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { Popup, SelectBox } from 'devextreme-react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { Button } from '@blueprintjs/core';
import DataGrid, {
    Column, Editing, MasterDetail, SearchPanel, Form,
    Popup as PopupGrid, Lookup, RequiredRule,
    Button as GridButton, Toolbar
} from 'devextreme-react/data-grid';
import { Item as FormItem } from 'devextreme-react/form';
import "font-awesome/css/font-awesome.min.css";
import mainStore from './store/mainStore';
import { alert, confirm } from './dialogs';
import DocItem from "./docItem";

const Doc = (props) => {
    const { visible, dataId } = props;
    const [value, setValue] = useState([]);
    const [docName, setDocName] = useState("");
    const [docId, setDocId] = useState();
    // const [uploadType, setUploadType] = useState("INSERT");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadPanelVisible, setLoadPanelVisible] = useState(false);
    const [sellerId, setSellerId] = useState();
    const [buyerId, setBuyerId] = useState();
    const [uploadVisible, setUploadVisible] = useState(false);

    useEffect(() => {
        setDocName("");
        setSelectedRowKeys([]);
        setValue([]);
        (
            async () => {
                await mainStore.getSellerSource();
                await mainStore.getBuyerSource();
                await mainStore.getTemplateSource();

                if (dataId && visible) {
                    await mainStore.getDocSource(dataId);
                    const obj = mainStore.gridSource.find(e => e.data_id === dataId);
                    setSellerId(obj.seller_id);
                    setBuyerId(obj.buyer_id);
                }
            }
        )();
    }, [visible, dataId]);

    const groupRender = (data) => {
        const text = data.displayValue;
        return (
            <div style={{ color: "steelblue", fontSize: "16px" }}> {text} </div>
        );
    }

    const cellRender = (data) => {
        if (!data.displayValue) return;
        let icon;
        switch (data.displayValue.split('.').pop().toLowerCase()) {
            case "doc":
            case "docx":
                icon = "fa fa-file-word-o";
                break;
            case "xls":
            case "xlsx":
                icon = "fa fa-file-excel-o";
                // icon="dx-icon-xlsfile"
                break;
            case "pdf":
                icon = "fa fa-file-pdf-o";
                break;
            case "jpg":
            case "jpeg":
            case "png":
            case "bmp":
                icon = "fa fa-file-image-o";
                // icon = "dx-icon-image";
                break;
            case "txt":
                icon = "fa fa-file-text-o";
                break;
            case "gz":
            case "zip":
            case "rar":
                icon = "fa fa-file-archive-o";
                break;
            default:
                icon = "fa fa-file-o";
                break;
        }
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <i className={icon} aria-hidden="true" style={{ color: "darkslategray", fontSize: "20px", fontWeight: 500, padding: "0px 8px" }}></i>
                {data.displayValue}
            </div>
        );
    }

    const downloadFile = async (docId) => {
        const ids = selectedRowKeys.length ? selectedRowKeys : [docId];

        if (!ids.length || !ids[0]) return;

        // const result = await confirm(`Скачать выбранные (${ids.length}) документы?`);
        // if (!result) return;

        const promises = ids.map(id => {
            return fetch(`/api/file?docId=${id}`);
        });

        setLoadPanelVisible(true);

        const response = await Promise.all(promises);

        setLoadPanelVisible(false);

        for (let i = 0; i < promises.length; i++) {
            const reader = response[i].body.getReader();

            let chunks = [];
            // let receivedLength = 0;

            while (true) {
                const { done, value } = await reader.read();

                if (done) {
                    break;
                }

                chunks.push(value);
                // receivedLength += value.length;
            }

            // Шаг 4: соединим фрагменты в общий типизированный массив Uint8Array
            const blob = new Blob(chunks);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);

            link.download = mainStore.docSource.find(el => el.doc_id === ids[i]).file_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    }

    const saveBuyer = async (e) => {
        const arr = e.changes.map(el => {
            if (el.type === "insert")
                delete el.data.face_id;
            if (el.data)
                el.data.face_type_id = 2;
            return el;
        });

        const res = await mainStore.updateBuyer(arr);
        if (res !== "OK") {
            await alert(res, "Ошибка");
            await mainStore.getBuyerSource();
        }
    }

    const saveSeller = async (e) => {
        const arr = e.changes.map(el => {
            if (el.type === "insert")
                delete el.data.face_id;
            if (el.data)
                el.data.face_type_id = 1;
            return el;
        });

        const res = await mainStore.updateBuyer(arr);
        if (res !== "OK") {
            await alert(res, "Ошибка");
            await mainStore.getBuyerSource();
        }
    }

    return (
        <>
            <LoadPanel
                visible={loadPanelVisible}
                showPane
                shading
                shadingColor="rgba(0,0,0,0.4)"
                message="Загрузка..."
                onHiding={() => setLoadPanelVisible(false)}
            />

            <Popup
                width={500}
                height={250}
                visible={uploadVisible}
                showTitle
                title="Загрузка"
                showCloseButton
                onShowing={() => { setValue([]); console.log(docId); }}
                onHiding={() => setUploadVisible(false)}
            >
                <FileUploader
                    height="100%"
                    multiple={false}
                    accept="*"
                    uploadMode="useButtons"
                    uploadMethod="POST"
                    uploadUrl="/api/upload"
                    name="file"
                    value={value}
                    onValueChanged={e => {
                        setValue(e.value);
                        // setUploadType("UPDATE");
                    }}
                    uploadHeaders={{
                        // dataId: dataId,
                        // docName: Buffer.from(docName).toString('base64'),
                        userid: mainStore.user.user_id,
                        docid: docId
                    }}
                    onUploaded={async () => { setUploadVisible(false); await mainStore.getDocSource(dataId) }}
                />
            </Popup>

            <TabPanel height="100%" iconPosition="start">
                <Item title="Список документов" icon="bulletlist">
                    <div style={{ display: 'flex', flexDirection: "column", justifyContent: "space-around", minHeight: "60px", margin: "5px 10px 5px 10px" }}>
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center" }}>
                            <div style={{ minWidth: "90px" }}> Продавец: </div>
                            <SelectBox
                                dataSource={mainStore.sellerSource}
                                valueExpr="face_id"
                                displayExpr="name"
                                value={sellerId}
                                onValueChanged={async (e) => {
                                    setSellerId(e.value);

                                    const obj = {
                                        data: {
                                            seller_id: e.value
                                        },
                                        key: dataId
                                    }
                                    await mainStore.updateData([obj]);
                                    const id = mainStore.statusKey;
                                    const o = mainStore.statusSource.find(el => el.id === id);
                                    await mainStore.getGridSource(o?.page_num, o?.status_id);
                                }}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", marginTop: "5px" }}>
                            <div style={{ minWidth: "90px" }}> Покупатель: </div>
                            <SelectBox
                                dataSource={mainStore.buyerSource}
                                valueExpr="face_id"
                                displayExpr="name"
                                value={buyerId}
                                onValueChanged={async (e) => {
                                    setBuyerId(e.value);

                                    const obj = {
                                        data: {
                                            buyer_id: e.value
                                        },
                                        key: dataId
                                    }
                                    await mainStore.updateData([obj]);
                                    const id = mainStore.statusKey;
                                    const o = mainStore.statusSource.find(el => el.id === id);
                                    await mainStore.getGridSource(o?.page_num, o?.status_id);
                                }}
                                style={{ width: "100%" }}
                            />
                        </div>
                    </div>
                    <div style={{ maxHeight: "540px", minHeight: "540px", display: "flex", flexDirection: "column" }}>
                        {/* <div style={{ display: 'flex', flexDirection: "row" }}>
                            <Button
                                text="Удалить"
                                icon="trash"
                                minimal
                                intent="danger"
                                style={{ margin: "2px 0 2px" }}
                                disabled={!selectedRowKeys.length} опи
                                onClick={() => {
                                    const result = confirm(`Удалить выбранные (${selectedRowKeys.length}) документы?`);
                                    result.then(async (res) => {
                                        if (res) {
                                            await mainStore.deleteDoc(selectedRowKeys);
                                            await mainStore.getDocSource(dataId);
                                        }
                                    });

                                }}
                            />
                            <Button
                                text="Скачать"
                                minimal
                                intent="primary"
                                icon="download"
                                disabled={!selectedRowKeys.length}
                                style={{ marginLeft: "1px" }}
                                onClick={downloadFile}
                            />

                            <Button
                                text="Тест"
                                minimal
                                intent="primary"
                                icon="download"
                                style={{ marginLeft: "1px" }}
                                onClick={() => setUploadVisible(true)}
                            />
                        </div> */}
                        <div style={{ maxHeight: "507px", margin: "10px 10px 0 10px", background: "linear-gradient(90deg, steelblue, aliceblue)", boxShadow: "-2px -2px 15px 0px darkgray", }}>
                            <DataGrid
                                // height="100%"
                                width="100%"
                                style={{ maxHeight: "500px" }}
                                dataSource={mainStore.docSource}
                                keyExpr="doc_id"
                                showColumnLines
                                showRowLines
                                showBorders

                                // focusedRowEnabled
                                // columnAutoWidth
                                focusedRowKey={docId}
                                onFocusedRowChanged={e => { setDocId(e.row?.key); }}
                                onSelectionChanged={e => setSelectedRowKeys(e.selectedRowKeys)}
                                selectedRowKeys={selectedRowKeys}
                                onSaved={async (e) => {
                                    const arr = e.changes.map(el => {
                                        if (el.data) {
                                            el.data.data_id = dataId;
                                            el.data.user_id = mainStore.user.user_id;
                                            el.data.doc_change_date = new Date();
                                            delete el.data.user_name;
                                        }
                                        return el;
                                    });
                                    await mainStore.updateDoc(arr);
                                    await mainStore.getDocSource(dataId);
                                }}
                            >
                                <Column dataField="doc_name" caption="Название документа" width={200} cellRender={e => <div style={{ color: "darkslategray", fontWeight: 600 }}> {e.displayValue} </div>}>
                                    <RequiredRule />
                                </Column>
                                <Column dataField="file_name" caption="Имя файла" cellRender={cellRender} width={240} allowEditing={false} />
                                <Column
                                    dataField="doc_type_id" caption="Вид документа" visible={true} groupIndex={0} groupCellRender={groupRender}
                                >
                                    <RequiredRule />
                                    <Lookup
                                        dataSource={[{ text: "Входящие", value: 1 }, { text: "Исходящие", value: 2 }]}
                                        valueExpr="value"
                                        displayExpr="text"
                                        allowClearing={false}
                                    />
                                </Column>
                                <Column dataField="template" caption="Шаблон" width={150}>
                                    <Lookup
                                        dataSource={mainStore.templateSource}
                                    />
                                </Column>
                                <Column dataField="doc_create_date" caption="Дата создания" dataType="datetime" width={130} allowEditing={false} />
                                <Column dataField="doc_change_date" caption="Дата изменения" dataType="datetime" width={130} allowEditing={false} />
                                <Column dataField="user_name" caption="Изменил" width={130} allowEditing={false} />
                                <Column dataField="doc_num" caption="№ документа" width={90} />
                                <Column dataField="doc_date" caption="Дата составления" dataType='date' width={110} />

                                <Column type="buttons" caption="Действие">
                                    <GridButton name="edit" />
                                    <GridButton name="delete" />
                                    <GridButton hint="Скачать" icon="download" onClick={async (e) => {
                                        if (!e.row.data.file_name) {
                                            alert("Файл отсутствует!", "Ошибка");
                                            return;
                                        }

                                        await downloadFile(e.row.data.doc_id);
                                    }} />
                                    <GridButton hint="Сформировать документ" icon="xlsxfile" />
                                    <GridButton hint="Загрузить" icon="upload" onClick={(e) => { setDocId(e.row.data.doc_id); setUploadVisible(true) }} />
                                </Column>

                                {/* <Selection mode="multiple" /> */}
                                <Editing allowUpdating allowAdding allowDeleting mode="popup">
                                    <PopupGrid
                                        title="Документ"
                                        showTitle
                                        width={450}
                                        height={480}
                                    />
                                    <Form>
                                        <FormItem
                                            itemType="group"
                                            colCount={1}
                                            colSpan={2}
                                        >
                                            <FormItem dataField="doc_type_id" />
                                            <FormItem dataField="doc_name" />
                                            <FormItem dataField="template" />
                                            <FormItem dataField="doc_num" />
                                            <FormItem dataField="doc_date" />
                                        </FormItem>
                                    </Form>

                                </Editing>

                                <MasterDetail
                                    enabled
                                    component={DocItem}
                                />

                                <Toolbar>
                                    <Item location="before">
                                        <div style={{ fontSize: "16px", fontWeight: 500, color: "white" }}> Документы </div>
                                    </Item>
                                    <Item name="addRowButton" />
                                    <Item name="saveButton" />
                                    <Item name="revertButton" />
                                </Toolbar>
                            </DataGrid>
                        </div>
                    </div>
                </Item>

                <Item title="Продавцы" icon="user">
                    <div style={{ background: "linear-gradient(90deg, steelblue, aliceblue)", boxShadow: "-2px -2px 15px 0px darkgray", margin: "10px 10px 0 10px" }}>
                        <DataGrid
                            dataSource={mainStore.sellerSource}
                            width="100%"
                            keyExpr="face_id"
                            columnAutoWidth
                            showColumnLines
                            showRowLines
                            showBorders
                            columnHidingEnabled
                            onSaved={saveSeller}
                        >
                            <Column dataField="ur_type" caption="Вид" hidingPriority={3} />
                            <Column dataField="fio" caption="Название/ФИО" hidingPriority={3} />
                            <Column dataField="bank" caption="Банк" hidingPriority={3} />
                            <Column dataField="address" caption="Адрес" hidingPriority={2} />
                            <Column dataField="inn" caption="ИНН" hidingPriority={1} />
                            <Column dataField="tel" caption="Тел" hidingPriority={1} />
                            <Column dataField="rs" caption="р/с" hidingPriority={0} />
                            <Column dataField="bik" caption="БИК" hidingPriority={0} />
                            <Column dataField="ks" caption="к/с" hidingPriority={0} />
                            <Column dataField="okpo" caption="ОКПО" hidingPriority={0} />
                            <Column dataField="okud" caption="ОКУД" hidingPriority={0} />

                            <Editing mode="batch" allowUpdating allowAdding allowDeleting />

                            <Toolbar>
                                <Item location="before">
                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "white" }}> Продавцы: </div>
                                </Item>
                                <Item name="addRowButton" />
                                <Item name="saveButton" />
                                <Item name="revertButton" />
                            </Toolbar>
                        </DataGrid>
                    </div>
                </Item>

                <Item title="Покупатели" icon="cart">
                    <div style={{ background: "linear-gradient(90deg, steelblue, aliceblue)", boxShadow: "-2px -2px 15px 0px darkgray", margin: "10px 10px 0 10px" }}>
                        <DataGrid
                            dataSource={mainStore.buyerSource}
                            width="100%"
                            keyExpr="face_id"
                            columnAutoWidth
                            showColumnLines
                            showRowLines
                            showBorders
                            columnHidingEnabled
                            onSaved={saveBuyer}
                        >
                            <Column dataField="ur_type" caption="Вид" hidingPriority={3} />
                            <Column dataField="fio" caption="Название/ФИО" hidingPriority={3} />
                            <Column dataField="bank" caption="Банк" hidingPriority={3} />
                            <Column dataField="address" caption="Адрес" hidingPriority={2} />
                            <Column dataField="inn" caption="ИНН" hidingPriority={1} />
                            <Column dataField="tel" caption="Тел" hidingPriority={1} />
                            <Column dataField="rs" caption="р/с" hidingPriority={0} />
                            <Column dataField="bik" caption="БИК" hidingPriority={0} />
                            <Column dataField="ks" caption="к/с" hidingPriority={0} />
                            <Column dataField="okpo" caption="ОКПО" hidingPriority={0} />
                            <Column dataField="okud" caption="ОКУД" hidingPriority={0} />

                            <Editing mode="batch" allowUpdating allowAdding allowDeleting />
                            <SearchPanel visible highlightSearchText />

                            <Toolbar>
                                <Item location="before">
                                    <div style={{ fontSize: "16px", fontWeight: 500, color: "white" }}> Покупатели: </div>
                                </Item>
                                <Item name="addRowButton" />
                                <Item name="saveButton" />
                                <Item name="revertButton" />
                            </Toolbar>
                        </DataGrid>
                    </div>
                </Item>

                {/* <Item title="Загрузка файлов" icon="upload" >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ padding: "7px" }}>
                            <div> Комментарий: </div>

                            <TextBox value={docName} onValueChanged={e => setDocName(e.value)} />

                            <div style={{ marginTop: "8px" }}> Тип документа: </div>

                            <SelectBox
                                dataSource={[{ text: "Входящие", value: true }, { text: "Исходящие", value: false }]}
                                valueExpr="value"
                                displayExpr="text"
                                value={isInput}
                                onValueChanged={e => setIsInput(e.value)}
                            />

                            <div style={{ marginTop: "8px" }}> Реквизиты продавца: </div>

                            <SelectBox
                                dataSource={mainStore.sellerSource}
                                valueExpr="face_id"
                                displayExpr="name"
                                value={sellerId}
                                onValueChanged={e => setSellerId(e.value)}
                            />

                            <div style={{ margin: "18px 0 8px", fontSize: "16px" }}> Вариант загрузки: </div>
                            <RadioGroup
                                items={[{ code: "INSERT", text: "Создать новый документ" }, { code: "UPDATE", text: "Обновить существующий" }]}
                                value={uploadType}
                                onValueChanged={e => setUploadType(e.value)}
                                valueExpr="code"
                                displayExpr="text"
                            />
                        </div>
                        <FileUploader
                            height="100%"
                            multiple={false}
                            accept="*"
                            uploadMode="useButtons"
                            uploadMethod="POST"
                            uploadUrl="/api/upload"
                            name="file"
                            value={value}
                            onValueChanged={e => {
                                setValue(e.value);
                                const file = e.value[0]?.name;
                                if (mainStore.docSource.find(el => el.file_name.toLowerCase() === file?.toLowerCase()))
                                    setUploadType("UPDATE");
                                else
                                    setUploadType("INSERT");
                            }}
                            uploadHeaders={{
                                dataId: dataId,
                                isInput: isInput,
                                docName: Buffer.from(docName).toString('base64'),
                                userid: mainStore.user.user_id,
                                uploadtype: uploadType,
                                docid: docId
                            }}
                            onUploaded={async () => await mainStore.getDocSource(dataId)}
                        />
                    </div>
                </Item> */}
            </TabPanel>
        </>
    );
}

export default observer(Doc);