import { observer } from 'mobx-react';
// import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FocusStyleManager } from '@blueprintjs/core';
import "@blueprintjs/core/lib/css/blueprint.css";
import './App.css';
import Main from "./main";
// import mainStore from './store/mainStore';
import Admin from './admin';

FocusStyleManager.onlyShowFocusOnTabs();

const App = () => {

    return (
        <BrowserRouter>
            <div className='App'>
                <Routes>
                    <Route exact path='/' element={<Main />} />
                    <Route exact path='/admin' element={<Admin />} />
                </Routes>
            </div>
        </BrowserRouter>
    );
}

export default observer(App);
