import { observer } from 'mobx-react';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Checkbox } from '@blueprintjs/core';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faBank } from '@fortawesome/free-solid-svg-icons';
import { Popup } from 'devextreme-react/popup'
import { ContextMenu, LoadPanel, Button as DxButton, List } from 'devextreme-react';
import DataGrid, {
    Column,
    // Format,
    StateStoring,
    HeaderFilter,
    GroupPanel,
    Pager,
    Paging,
    Scrolling,
    Search,
    Selection,
    Grouping,
    // Lookup,
    FilterPanel,
    Editing,
    Sorting,
    ColumnChooser,
    Toolbar as DxToolbar,
    Item,
    SearchPanel
} from 'devextreme-react/data-grid';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
// import 'devextreme/dist/css/dx.light.css';
import mainStore from './store/mainStore';
// import { showWaiting, closeWaiting } from "./waiting";
import Doc from './doc';
import { confirm } from './dialogs';
import { toJS } from 'mobx';
import './App.css';

import "font-awesome/css/font-awesome.min.css";

import config from "devextreme/core/config";
 
config({
    defaultCurrency: 'RUB'
});

const Main = () => {
    loadMessages(ruMessages);
    locale(navigator.language);

    const dataGrid = useRef();

    // const [downloadConfirmVisible, setDownloadConfirmVisible] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [dataId, setDataId] = useState();
    const [docsVisible, setDocsVisible] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isAll, setIsAll] = useState(false);
    const [number, setNumber] = useState();
    const [loadPanelVisible, setLoadPanelVisible] = useState(false);
    const [statusName, setStatusName] = useState();
    const [editing, setEditing] = useState(false);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        const load = async () => {
            await mainStore.getStatusSource();

            await mainStore.getUser();
            await mainStore.getAvailableStatusSource();
            await mainStore.getMethodSource();

            const arr = mainStore.availableStatusSource.map(s => {
                return {
                    text: s.status_name,
                    statusId: s.status_id
                }
            });
            const items = [
                { text: "Изменить статус", items: arr, icon: "sun" },
                { text: "Документы", code: "doc", icon: "doc" },
                { text: "Удалить", code: "delete", icon: "remove", beginGroup: true }
            ];
            setMenuItems(items);

            const id = JSON.parse(localStorage.getItem("status_state"))?.focusedRowKey;
            await refresh(id);
        }
        load();
    }, []);

    const renderGroup = (data) => {
        return <div style={{ fontSize: '16px' }}> {data.displayValue} </div>;
    }

    // const renderGroupOther = (data) => {
    //     return <div style={{ color: "white", fontSize: '16x' }}> {data.displayValue} </div>;
    // }

    // const dateCellRender = (data) => {
    //     return <div style={{ color: "darkred", fontSize: '13px' }}>{formatDate(data.value)}</div>
    // }

    const formatDate = (d) => {
        if (!d) return '';
        d = Date.parse(d);

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }
        return new Intl.DateTimeFormat('ru-RU', options).format(d).replace(',', '');
    }
    const formatDatetime = (d) => {
        if (!d) return '';
        d = Date.parse(d);

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
        return new Intl.DateTimeFormat('ru-RU', options).format(d).replace(',', '');
    }

    const statusCellRender = (data) => {
        const color = data.data.color;
        return (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ color: color, padding: "5px", fontSize: "14px", fontWeight: 600 }}> {data.displayValue} </div>
                {/* <div style={{ flexGrow: 1 }} /> */}
                <div style={{ backgroundColor: "#03a9f4", display: "flex", alignItems: "center", justifyContent: "center", color: "white", fontSize: "10px", fontWeight: 600, width: "23px", height: "23px", borderRadius: "50%" }}> {data.data.cnt} </div>
            </div>
        );
    }

    const groupCellTemplate = (el, data) => {
        let contentEl = document.createElement("div");
        let val = new Date(data.value);
        if (val.toString().indexOf('Invalid') >= 0) {
            val = data.value;
        }
        else
            val = formatDate(data.value);
        contentEl.innerHTML = `
            <a target="_blank" rel="noreferrer">
                ${val}
            </a>
        `;
        el.append(contentEl);
    }

    const numberTemplate = (el, data) => {
        if (!data.data.number_text) return;
        let contentEl = document.createElement("div");
        contentEl.innerHTML = `
            <a href=${data.data.number_hyperlink} target="_blank" rel="noreferrer">
                ${data.data.number_text}
            </a>
        `;
        el.append(contentEl);
    }

    const eisTemplate = (el, data) => {
        if (!data.data.eis_text) return;
        let contentEl = document.createElement("div");
        contentEl.innerHTML = `
            <a href=${data.data.eis_hyperlink} target="_blank" rel="noreferrer">
                ${data.data.eis_text}
            </a>
        `;
        el.append(contentEl);
    }

    const etpTemplate = (el, data) => {
        if (!data.data.etp_text) return;
        let contentEl = document.createElement("div");
        contentEl.innerHTML = `
            <a href=${data.data.etp_hyperlink} target="_blank" rel="noreferrer">
                ${data.data.etp_text}
            </a>
        `;
        el.append(contentEl);
    }

    const refresh = async (id) => {
        // showWaiting("Получение данных...");
        setLoadPanelVisible(true);
        id = id ?? mainStore.statusKey;
        mainStore.setStatusKey(id);
        const obj = mainStore.statusSource.find(el => el.id === id);

        await mainStore.getGridSource(obj?.page_num, obj?.status_id);

        // console.log(dataGrid.current.instance);
        const state = localStorage.getItem(`maingrid_status_${obj?.status_id}`);

        if (state) {
            // dataGrid.current.instance.state(JSON.parse(state));
        }
        else
            dataGrid.current.instance.state(null);
        // 
        // closeWaiting();
        setLoadPanelVisible(false);
    }

    const handleStatusChanged = async (e) => {
        setIsAll(false);
        mainStore.setStatusKey(e.value);
        const obj = mainStore.statusSource.find(el => el.id === e.row?.key);
        // const state = dataGrid.current.instance.state();
        // localStorage.setItem(`maingrid_status_${obj?.status_id}`, JSON.stringify(state));

        dataGrid.current.instance.beginUpdate();

        const cols = (await mainStore.getColumnMatrixSource()).map(el => {
            let cellTemplate;
            let sortIndex = -1;

            switch (el.column_name) {
                case "number_text":
                    cellTemplate = numberTemplate;
                    break;
                case "eis_text":
                    cellTemplate = eisTemplate;
                    break;
                case "etp_text":
                    cellTemplate = etpTemplate;
                    break;
                default:
                    cellTemplate = undefined;
                    break;
            }

            return {
                dataField: el.column_name,
                dataType: el.data_type,
                caption: el.description,
                allowEditing: el.editable,
                width: el.width,
                visible: el[obj?.status_id],
                cellTemplate: cellTemplate,
                groupCellTemplate: groupCellTemplate,
                sortIndex: el.sort ? ++sortIndex : -1,
                sortOrder: el.sort ? "asc" : "none",
                format: el.data_type === "number" ? "#,##0.00" : undefined
                // groupCellRender: el.data_type === "date" ? dateCellRender : undefined
            }
        });
        // console.log(cols);

        setColumns(cols);

        dataGrid.current.instance.endUpdate();

        await refresh(e.row?.key);

        setStatusName(obj?.status_name);
    }

    const updateData = async (e) => {
        await mainStore.updateData(e.changes);
        await mainStore.getStatusSource();

        // const obj = mainStore.statusSource.find(el => el.id === mainStore.statusKey);
        // await mainStore.getGridSource(obj.page_num, obj.status_id);
    }

    return (
        <div className="App">
            <LoadPanel
                visible={loadPanelVisible}
                showPane
                shading
                shadingColor="rgba(0,0,0,0.4)"
                message="Загрузка..."
                onHiding={() => setLoadPanelVisible(false)}
            />

            <Toolbar>
                <div style={{ display: "flex", padding: "10px", fontWeight: 500, fontSize: "18px" }}>
                    <FontAwesomeIcon icon={faBank} style={{ height: "25px", width: "25px", marginRight: "10px" }} />
                    Аукционы
                </div>
                <div style={{ flexGrow: 1 }}></div>
                {
                    mainStore.user.isadmin
                        ?
                        <Link to="/admin" style={{ fontWeight: "600", marginRight: "15px", color: "#0a3a62" }}> {mainStore.user.user_name} </Link>
                        :
                        <div style={{ fontWeight: "600", marginRight: "15px", color: "#0a3a62" }}> {mainStore.user.user_name} </div>
                }
                <Button onClick={async () => {
                    const result = await confirm("Загрузить новые данные?");
                    if (!result) return;

                    setLoadPanelVisible(true);
                    // showWaiting("Выполняется загрузка...");
                    await mainStore.download();
                    setLoadPanelVisible(false);
                }}>
                    Загрузить...
                </Button>
            </Toolbar>

            <ContextMenu
                dataSource={menuItems}
                target="#dataGrid"
                onItemClick={async (e) => {
                    const refresh = async () => {
                        await mainStore.getStatusSource();

                        if (isAll)
                            await mainStore.getGridSource();
                        else {
                            const obj = mainStore.statusSource.find(el => el.id === mainStore.statusKey);
                            await mainStore.getGridSource(obj?.page_num, obj?.status_id);
                        }
                    }

                    switch (e.itemData.code) {
                        case "doc":
                            setDocsVisible(true);
                            break;
                        case "delete":
                            if (!selectedRowKeys.length) {
                                const result = await confirm("Удалить текущую позицию?");
                                if (!result) return;

                                await mainStore.deleteData([dataId]);
                                await refresh();
                            }
                            else {
                                const result = await confirm(`Удалить выбранные (${selectedRowKeys.length}) аукционы?`);
                                if (!result) return;

                                await mainStore.deleteData(selectedRowKeys);
                                await refresh();
                            }

                            break;
                        default: // Статусы
                            const result = await confirm(`Изменить статус выбранных (${selectedRowKeys.length || 1}) аукционов?`);

                            if (!result) return;

                            const data = [...toJS(mainStore.gridSource)];

                            if (selectedRowKeys.length <= 1) {
                                const i = data.findIndex(el => el.data_id === dataId);

                                data[i].status_id = e.itemData.statusId;
                                data[i].date_change = new Date();
                                data[i].status_name = e.itemData.text;

                                const obj = {
                                    data: {
                                        status_id: e.itemData.statusId,
                                        date_change: new Date()
                                    },
                                    key: dataId
                                }

                                mainStore.setGridSource(data);

                                await mainStore.updateData([obj]);

                                await mainStore.getStatusSource();
                            }
                            else {
                                const objs = [];
                                const now = new Date();

                                selectedRowKeys.forEach(id => {
                                    const idx = data.findIndex(el => el.data_id === id);
                                    data[idx].status_id = e.itemData.statusId;
                                    data[idx].date_change = now;
                                    data[idx].status_name = e.itemData.text;

                                    objs.push({
                                        data: {
                                            status_id: e.itemData.statusId,
                                            date_change: now
                                        },
                                        key: id
                                    });
                                });

                                mainStore.setGridSource(data);

                                await mainStore.updateData(objs);
                                await mainStore.getStatusSource();
                            }

                            break;
                    }
                }}
            />

            {
                docsVisible
                    ?
                    <Popup
                        width={1450}
                        height={700}
                        visible={docsVisible}
                        showTitle
                        title={`Документы - ${number}`}
                        showCloseButton
                        onHiding={() => setDocsVisible(false)}
                    >
                        <Doc visible={docsVisible} dataId={dataId} />
                    </Popup>
                    :
                    null
            }

            <PanelGroup direction="horizontal" autoSaveId="auction">
                <Panel defaultSize={17} style={{ display: "flex", flexDirection: "column" }}>
                    <CaptionDiv>
                        Статусы
                    </CaptionDiv>
                    <DataGrid
                        dataSource={mainStore.statusSource}
                        height="100%"
                        keyExpr="id"
                        showColumnHeaders={false}
                        showBorders={false}
                        showColumnLines={false}
                        showRowLines
                        autoNavigateToFocusedRow
                        focusedRowEnabled
                        focusedRowKey={mainStore.statusKey}
                        onFocusedRowChanged={handleStatusChanged}
                        onFocusedRowChanging={e => { e.cancel = e.rows[e.newRowIndex].rowType === "group" }}
                        onCellPrepared={e => {
                            if (e.rowType === "group") {
                                e.cellElement.style.backgroundColor = "rgb(10 58 98 / 56%)";
                                e.cellElement.style.color = "white";
                            }
                        }}
                    >
                        <Column dataField='page_name' groupIndex={0} visible={false} groupCellRender={renderGroup} />
                        <Column dataField='status_name' caption='Статус' cellRender={statusCellRender} />
                        {/* <Column dataField='Cnt' caption=' '  width={55}/> */}

                        <GroupPanel visible={false} />
                        <StateStoring enabled type="localStorage" storageKey="status_state" />
                    </DataGrid>

                    {/* <List
                        dataSource={groupBy("page_name", mainStore.statusSource).map(e => {
                            e.key = e.page_name;
                            delete e.page_name;
                            return e;
                        })}
                        grouped
                        collapsibleGroups
                        keyExpr="id"
                        displayExpr="status_name"
                        focusedStateEnabled
                        activeStateEnabled
                        selectionMode="single"
                        itemRender={item => {
                            return (
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                    <div style={{ display: "flex", color: item.color, paddingLeft: "10px", fontSize: "14px", fontWeight: 600 }}> {item.status_name} </div>
                                    <div style={{ backgroundColor: "#03a9f4", display: "flex", alignItems: "center", justifyContent: "center", color: "white", fontSize: "10px", fontWeight: 600, width: "23px", height: "23px", borderRadius: "50%" }}> {item.cnt} </div>
                                </div>
                            );
                        }}
                        groupRender={item => {
                            return (
                                <div style={{ fontSize: "18px", fontWeight: 600, color: "white" }}> {item.key} </div>
                            )
                        }}
                    /> */}


                    {/* <div style={{height: "45px", backgroundColor: "aliceblue", display: "flex", justifyContent: "flex-start", paddingLeft: "47px", marginTop: "10px", alignItems: "center", fontSize: "14px"}}> Все статусы </div> */}
                </Panel>

                <PanelResizeHandle style={{ width: "4px", border: "1px groove #e7e7e7" }} />

                <Panel style={{ display: "flex", flexDirection: "column" }}>
                    <CaptionDiv style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        Данные {isAll ? "(все статусы)" : `(статус: ${statusName})`}
                        <div style={{ flexGrow: 1 }} />
                        <Checkbox
                            large
                            style={{ margin: "2px 10px 0", fontSize: "14px" }}
                            label="Все статусы"
                            checked={isAll}
                            onChange={async (e) => {
                                // showWaiting("Получение данных...");
                                setLoadPanelVisible(true);
                                setIsAll(e.target.checked);
                                if (e.target.checked) {
                                    await mainStore.getGridSource();
                                }
                                else {
                                    const id = mainStore.statusKey;
                                    const obj = mainStore.statusSource.find(el => el.id === id);
                                    await mainStore.getGridSource(obj?.page_num, obj?.status_id);
                                }
                                // closeWaiting();
                                setLoadPanelVisible(false);
                            }}
                        />
                        {/* <AnchorButton text="Поиск" icon="search" minimal style={{ color: "white" }} onClick={() => setSearchVisible(true)} /> */}
                    </CaptionDiv>
                    <DataGrid
                        id="dataGrid"
                        ref={dataGrid}
                        height="96%"
                        // width="1000px"
                        keyExpr="data_id"
                        columnResizingMode="widget"
                        dataSource={mainStore.gridSource}
                        columnAutoWidth
                        focusedRowEnabled
                        focusedRowKey={dataId}
                        onFocusedRowChanged={(e) => { setDataId(e.row?.data?.data_id); setNumber(e.row?.data?.number_text) }}
                        showRowLines
                        showColumnLines
                        wordWrapEnabled
                        allowColumnResizing
                        allowColumnReordering
                        rowAlternationEnabled
                        onEditingStart={() => setEditing(true)}
                        onEditCanceled={() => setEditing(false)}
                        onSelectionChanged={e => setSelectedRowKeys(e.selectedRowKeys)}
                        selectedRowKeys={selectedRowKeys}
                        onSaved={updateData}
                        onCellPrepared={e => {
                            if (e.rowType === "data" && e.column.allowEditing) {
                                e.cellElement.style.backgroundColor = "azure"
                            }
                        }}
                        columns={columns}
                    >
                        <GroupPanel visible />
                        <Grouping autoExpandAll={false} />
                        <FilterPanel visible={false} />
                        <ColumnChooser enabled mode="select" height="730px" width={300} />
                        <SearchPanel visible highlightSearchText />
                        <HeaderFilter visible height="600px" width="350px">
                            <Search enabled />
                        </HeaderFilter>
                        <Editing allowUpdating mode="batch" />
                        <Sorting mode="multiple" />

                        <Scrolling mode='virtual' useNative />
                        <Selection mode="multiple" />

                        <Pager
                            visible={true}
                            allowedPageSizes={true}
                            showPageSizeSelector={true}
                            displayMode="full"
                            showInfo={true}
                            showNavigationButtons={true}
                        />

                        <Paging defaultPageSize={50} />

                        <DxToolbar>
                            <Item location="after">
                                <DxButton icon="taskrejected" type="danger" hint="Сброс настроек" onClick={
                                    async () => {
                                        const res = await confirm("Сбросить настройки грида на значения по умолчанию?");
                                        if (res)
                                            dataGrid.current.instance.state(null);
                                    }
                                } />
                            </Item>
                            {/* <Item name="saveButton" /> */}
                            <Item>
                                <DxButton icon="check" type="success" hint="Сохранить изменения" disabled={!editing} onClick={() => { setEditing(false); dataGrid.current.instance.saveEditData(); }} />
                            </Item>
                            {/* <Item name="revertButton" /> */}
                            <Item>
                                <DxButton icon="revert" type="default" disabled={!editing} hint="Отменить изменения" onClick={() => { setEditing(false); dataGrid.current.instance.cancelEditData(); }} />
                            </Item>
                            <Item name="searchPanel" />
                            <Item name="groupPanel" />
                            {/* <Item name="columnChooserButton" /> */}
                            <Item>
                                <DxButton icon="fields" stylingMode='outlined' type="normal" hint="Выбор столбцов" onClick={() => { dataGrid.current.instance.showColumnChooser() }} />
                            </Item>
                        </DxToolbar>
                    </DataGrid>
                </Panel>
            </PanelGroup>

            {/* <ConfirmDialog
                message="Загрузить новые данные?"
                visible={downloadConfirmVisible}
                onHide={() => setDownloadConfirmVisible(false)}
                onConfirm={async () => {
                    setDownloadConfirmVisible(false);
                    showWaiting("Выполняется загрузка...");
                    await mainStore.download();
                    closeWaiting();
                }}
            /> */}
        </div>
    );
}

// const ModuleHeader = styled.div`
//     font-size: 24px;
//     font-weight: 500;
//     margin: 5px 0 5px 10px;
//     line-height: 2;
//     color: whitesmoke;
//     display: flex;
//     flex-direction: row;
//     width: 100%;
//     align-items: center;
// `;

export const CaptionDiv = styled.div`
    min-height: 35px;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 8px;
    background-color: #0a3a62;
    color: white;
    font-size: 18px;
    font-weight: 500;
    border: 1px ridge #dee1e3;
`;

export const Toolbar = styled.div`
    height: 45px;
    //background-color: aliceblue;
    background: linear-gradient(45deg, #0a3a62, aliceblue);
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 7px;
    margin: 3px 3px 2px 3px;
    border: 1px ridge #dee1e3;
    color: whitesmoke;
    font-size: 18px;
    font-weight: 500;
`;

const groupBy = (key, array) => {
    var result = [];
    for (var i = 0; i < array.length; i++) {
        var added = false;
        for (var j = 0; j < result.length; j++) {
            if (result[j][key] === array[i][key]) {
                result[j].items.push(array[i]);
                added = true;
                break;
            }
        }
        if (!added) {
            var entry = { items: [] };
            entry[key] = array[i][key];
            entry.items.push(array[i]);
            result.push(entry);
        }
    }
    return result;
}

export default observer(Main);