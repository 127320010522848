import Modal from 'react-bootstrap/Modal';
import { TextBox } from 'devextreme-react';
import { Row } from 'react-bootstrap';
import styled from "styled-components";
import notify from "devextreme/ui/notify"
import { useState, useEffect } from 'react';
import { Button85, MyModalHeader, MyModalTitle } from './dialogs';

const PasswordEditForm = (props) => {
    const { visible, onOkClick, onCancelClick } = props;
    const [show, setShow] = useState(visible);
    const [pass1, setPass1] = useState();
    const [pass2, setPass2] = useState();

    useEffect(() => {
        setPass1(null);
        setPass2(null);
        setShow(visible);
    }, [visible]);


    const handleOkClick = () => {
        if (!pass1 || !pass2 || pass1.length < 6 || pass2.length < 6) {
            notify({ message: 'Длина пароля должна быть не менее 6 символов!', position: 'center', width: 400 }, "error", 2000);
            return;
        }
        if (pass1 !== pass2) {
            notify({ message: 'Пароли не совпадают!', position: 'center', width: 300 }, "error", 1000);
            return;
        }

        setShow(false);

        onOkClick({
            pass: pass1
        });
    }

    return (
        <Modal show={show} centered onHide={() => { setShow(false); onCancelClick(); }}>
            <MyModalHeader closeButton>
                <MyModalTitle> Смена пароля </MyModalTitle>
            </MyModalHeader>

            <Modal.Body>
                <Row>
                    <RowDiv>
                        <div> Новый пароль: </div>
                        <TextBox mode="password" value={pass1} onValueChanged={e => setPass1(e.value)}></TextBox>
                    </RowDiv>
                    <RowDiv>
                        <div> Подтверждение пароля: </div>
                        <TextBox mode="password" value={pass2} onValueChanged={e => setPass2(e.value)}></TextBox>
                    </RowDiv>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button85 variant="primary" onClick={handleOkClick}>
                    OK
                </Button85>
                <Button85 variant="secondary" onClick={() => {
                    setShow(false);
                    onCancelClick();
                }}>
                    Отмена
                </Button85>
            </Modal.Footer>
        </Modal>
    );
}

const RowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

export default PasswordEditForm;