import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { faQuestionCircle, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alert as dxAlert, confirm as dxConfirm } from 'devextreme/ui/dialog';

export const ConfirmDialog = ({visible, onHide, onConfirm, id, message}) => {
    return (
        <Modal show={visible} centered onHide={onHide}>
            <MyModalHeader closeButton>
                <MyModalTitle> Подтверждение </MyModalTitle>
            </MyModalHeader>
            <Modal.Body>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FontAwesomeIcon icon={faQuestionCircle} style={{height: "45px", width: "45px", color: "steelblue"}}/>
                    <div style={{ fontSize: "18px", marginLeft: "20px" }}> {message} </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button85 variant="primary" onClick={() => onConfirm(id)}>
                    OK
                </Button85>
                <Button85 variant="secondary" onClick={onHide}>
                    Отмена
                </Button85>
            </Modal.Footer>
        </Modal>
    );
}

export const ErrorDialog = ({visible, onHide, message}) => {
    return (
        <Modal show={visible} centered onHide={onHide}>
            <MyModalHeader closeButton>
                <MyModalTitle> Ошибка </MyModalTitle>
            </MyModalHeader>
            <Modal.Body>

                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <FontAwesomeIcon icon={faWarning} style={{height: "45px", width: "45px", color: "red"}}/>
                    <div style={{ fontSize: "18px", marginLeft: "20px" }}> {message} </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button85 variant="secondary" onClick={onHide}>
                    OK
                </Button85>
            </Modal.Footer>
        </Modal>
    );
}

const iconQuest = `<i class="fa fa-question-circle" aria-hidden="true" style="font-size: 45px;color: steelblue; margin-right: 10px"></i>`;
const iconExclam = `<i class="fa fa-exclamation-triangle" aria-hidden="true" style="font-size: 45px;color: crimson; margin-right: 10px"></i>`;

export const confirm = (message, title) => {
    return dxConfirm(`
        <div style="display: flex; font-size: 15px; align-items: center"> 
            ${iconQuest} 
            ${message}
        </div>`,
        title || "Подтверждение");
}

export const alert = (message, title) => {
    return dxAlert(`
        <div style="display: flex; font-size: 15px; align-items: center"> 
            ${iconExclam} 
            ${message}
        </div>`,
        title || "Внимание");
}

export const Button85 = styled(Button)`
    width: 85px;
`;

export const MyModalHeader = styled(Modal.Header)`
    background-color: steelblue;
    color: whitesmoke;
    padding: 5px 10px 5px 10px;
`;

export const MyModalTitle = styled(Modal.Title)`
    font-size: 18px;
`;
