import Modal from 'react-bootstrap/Modal';
import { TextBox } from 'devextreme-react';
import { Row } from 'react-bootstrap';
import styled from "styled-components";
import notify from "devextreme/ui/notify"
import { useState, useEffect } from 'react';
import ColorBox from 'devextreme-react/color-box';
import { Button85, MyModalHeader, MyModalTitle } from './dialogs';
import mainStore from './store/mainStore';

const StatusEditForm = (props) => {
    const { visible, onOkClick, onCancelClick, editStatus, statusId } = props;

    const [show, setShow] = useState(visible);
    const [statusName, setStatusName] = useState();
    const [color, setColor] = useState("black");

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    useEffect(() => {
        if (editStatus !== "EDIT") {
            setStatusName(null);
            setColor("black");
            return;
        };

        const obj = mainStore.statusAdminSource.find(e => e.status_id === statusId);

        setStatusName(obj?.status_name);
        setColor(obj?.color);
    }, [editStatus, statusId]);

    const handleOkClick = () => {
        if (!statusName) {
            notify({ message: 'Не заполнены обязательные поля!', position: 'center', width: 300 }, "error", 2000);
            return;
        }

        setShow(false);

        onOkClick({
            statusName: statusName,
            color: color
        });
    }

    return (
        <Modal show={show} centered onHide={() => { setShow(false); onCancelClick(); }}>
            <MyModalHeader closeButton>
                <MyModalTitle> {editStatus === "INSERT" ? "Новый статус" : "Изменить статус"} </MyModalTitle>
            </MyModalHeader>

            <Modal.Body>
                <Row>
                    <RowDiv>
                        <div> Название статуса: </div>
                        <TextBox value={statusName} onValueChanged={e => setStatusName(e.value)}></TextBox>
                    </RowDiv>
                    <RowDiv>
                        <div> Цвет: </div>
                        <ColorBox value={color} onValueChanged={e => setColor(e.value)} editAlphaChannel />
                    </RowDiv>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button85 variant="primary" onClick={handleOkClick} disabled={!statusName}>
                    OK
                </Button85>
                <Button85 variant="secondary" onClick={() => {
                    setShow(false);
                    onCancelClick();
                }}>
                    Отмена
                </Button85>
            </Modal.Footer>
        </Modal>
    );
}

const RowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

export default StatusEditForm;