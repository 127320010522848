import { action, observable, makeObservable } from 'mobx';
import { toJS } from 'mobx';
import http from "./http";

class MainStore {
    statusSource = [];
    statusAdminSource = [];
    gridSource = [];
    userSource = [];
    matrixSource = [];
    columnMatrixSource = [];
    availableStatusSource = [];
    methodSource = [];
    docSource = [];
    docItemSource = [];
    templateSource = [];
    sellerSource = [];
    buyerSource = [];
    user = {};
    statusKey = 0;

    constructor() {
        makeObservable(this, {
            statusSource: observable,
            statusAdminSource: observable,
            gridSource: observable,
            userSource: observable,
            matrixSource: observable,
            columnMatrixSource: observable,
            availableStatusSource: observable,
            methodSource: observable,
            docSource: observable,
            docItemSource: observable,
            templateSource: observable,
            sellerSource: observable,
            buyerSource: observable,
            user: observable,
            statusKey: observable,

            setStatusSource: action,
            setStatusAdminSource: action,
            setGridSource: action,
            setUserSource: action,
            setMatrixSource: action,
            setColumnMatrixSource: action,
            setAvailableStatusSource: action,
            setMethodSource: action,
            setDocSource: action,
            setDocItemSource: action,
            setTemplateSource: action,
            setSellerSource: action,
            setBuyerSource: action,
            setUser: action,
            setStatusKey: action
        });
    }

    getStatusSource = async () => {
        const data = await http.get("/api/statusList");
        this.setStatusSource(data);
    }

    getGridSource = async (pageNum, statusId) => {
        let data;

        if (pageNum && statusId)
            data = await http.get(`/api/dataGrid?pageNum=${pageNum}&statusId=${statusId}`);
        else
            data = await http.get(`/api/dataGrid`);

        this.setGridSource(data ?? []);
    }

    getMethodSource = async () => {
        const data = await http.get("/api/method");
        this.setMethodSource(data);
    }

    getUserSource = async () => {
        const data = await http.get("/api/users");
        this.setUserSource(data);
    }

    getUser = async () => {
        const who = await http.get("/api/who");
        if (who) {
            this.setUser(who);
        }
    }

    updateUser = async (data) => {
        await http.put("/api/users", data);
    }

    insertUser = async (data) => {
        await http.post("/api/users", data);
    }

    deleteUser = async (userId) => {
        return await http.delete("/api/users", { userId: userId });
    }

    changePassword = async (userId, pass) => {
        await http.post("/api/chpass", {userId: userId, pass: pass});
    }

    download = async () => {
        await http.get("/api/download");
        await this.getStatusSource();
        if (this.statusKey) {
            const obj = this.statusSource.find(e => e.id === this.statusKey);
            if (obj) {
                this.getGridSource(obj.page_num, obj.status_id);
            }
        }
    }

    updateStatus = async (data) => {
        await http.put("/api/status", {
            statusId: data.status_id,
            statusName: data.status_name,
            color: data.color,
            orderNo: data.order_no
        });
    }

    updateColumnOrder = async (data) => {
        await http.put("/api/column", data);
    }

    updateData = async (data) => {
        await http.put("/api/data", data);
    }

    insertStatus = async (data) => {
        return Number((await http.post("/api/status", data)).data.statusId);
    }

    deleteStatus = async (statusId) => {
        return await http.delete("/api/status", {
            statusId: statusId
        });
    }

    getStatusAdminSource = async () => {
        const data = await http.get("/api/statusAdmin");
        this.setStatusAdminSource(data);
    }

    getMatrixSource = async () => {
        const data = await http.get("/api/matrix");
        this.setMatrixSource(data);
    }

    getAvailableStatusSource = async () => {
        const data = await http.get(`/api/availableStatus?userId=${this.user.user_id}`);
        this.setAvailableStatusSource(data);
    }

    updateMatrix = async (data) => {
        await http.post("/api/matrix", data);
    }

    updateColumnMatrix = async (data) => {
        await http.post("/api/columnMatrix", data);
    }

    getDocSource = async (dataId) => {
        const data = await http.get(`/api/doc?dataId=${dataId}`);
        this.setDocSource(data);
    }

    getDocItemSource = async (docId) => {
        const data = await http.get(`/api/doc_item?docId=${docId}`);
        this.setDocItemSource(data);
    }

    getTemplateSource = async () => {
        const data = await http.get(`/api/template`);
        this.setTemplateSource(data);
    }

    deleteDoc = async (ids) => {
        return await http.delete("/api/doc", ids);
    }

    updateDoc = async (data) => {
        await http.put("/api/doc", data);
    }

    updateDocItem = async (data) => {
        await http.put("/api/doc_item", data);
    }

    deleteData = async (ids) => {
        return await http.delete("/api/dataGrid", ids);
    }

    getColumnMatrixSource = async () => {
        const data = await http.get(`/api/columnMatrix`);
        this.setColumnMatrixSource(data);
        return data;
    }

    getSellerSource = async () => {
        const data = await http.get(`/api/seller`);
        this.setSellerSource(data);
        return data;
    }

    getBuyerSource = async () => {
        const data = await http.get(`/api/buyer`);
        this.setBuyerSource(data);
        return data;
    }

    updateBuyer = async (data) => {
        const res = await http.put("/api/buyer", data);
        return res;
    }

    setStatusSource = (data) => this.statusSource = [...data];
    setStatusAdminSource = (data) => this.statusAdminSource = [...data];
    setGridSource = (data) => this.gridSource = [...data];
    setUserSource = (data) => this.userSource = [...data];
    setMatrixSource = (data) => this.matrixSource = [...data];
    setColumnMatrixSource = (data) => this.columnMatrixSource = [...data];
    setUser = (data) => this.user = { ...data };
    setStatusKey = (data) => this.statusKey = data;
    setAvailableStatusSource = (data) => this.availableStatusSource = [...data];
    setMethodSource = (data) => this.methodSource = [...data];
    setDocSource = (data) => this.docSource = [...data];
    setDocItemSource = (data) => this.docItemSource = [...data];
    setTemplateSource = (data) => this.templateSource = [...data];
    setSellerSource = (data) => this.sellerSource = [...data];
    setBuyerSource = (data) => this.buyerSource = [...data];
}

const mainStore = new MainStore();

export default mainStore;
