import { observer } from 'mobx-react';
import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";
import { ContextMenu } from "devextreme-react";
import DataGrid, { Column, Paging, RowDragging, Sorting, Editing, Scrolling, Pager } from 'devextreme-react/data-grid';
import mainStore from './store/mainStore';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { faUserCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "font-awesome/css/font-awesome.min.css";
import { toJS } from 'mobx';
import { Checkbox } from '@blueprintjs/core';
import { Toolbar } from "./main";
import { LoadPanel } from 'devextreme-react';
import PasswordEditForm from './passwordEditForm';
import UserEditForm from './userEditForm';
import { alert, confirm } from './dialogs';
import StatusEditForm from './statusEditForm';

const headerTemplate = (el, data) => {
    let contentEl = document.createElement("div");
    contentEl.innerHTML = `
        <div style="transform: rotate(270deg); height: 240px; color: ${data.column.color}; display: flex; align-items: center; justify-content: center"> 
            ${data.column.caption} 
        </div>`;
    el.append(contentEl);
};

const Admin = () => {
    loadMessages(ruMessages);
    locale(navigator.language);

    const [passEditFormVisible, setPassEditFormVisible] = useState(false);
    const [userEditFormVisible, setUserEditFormVisible] = useState(false);
    const [editStatus, setEditStatus] = useState("BROWSE");
    const [userId, setUserId] = useState();
    const [statusId, setStatusId] = useState();
    const [statusEditFormVisible, setStatusEditFormVisible] = useState(false);
    const [columns, setColumns] = useState([]);
    const [loadPanelVisible, setLoadPanelVisible] = useState(false);

    const matrixGrid = useRef();

    useEffect(() => {
        const load = async () => {
            await mainStore.getUser();
            await mainStore.getUserSource();
            await mainStore.getStatusAdminSource();
            await mainStore.getMatrixSource();
            await mainStore.getColumnMatrixSource();

            // console.log(mainStore.statusAdminSource);

            const cols = [
                { dataField: "description", caption: "Название столбца", width: "300px", allowEditing: false, fixed: true },
                { dataField: "editable", caption: "Редакт?", width: "80px", fixed: true, allowEditing: true },
                { dataField: "width", caption: "Ширина", width: "80px", fixed: true, allowEditing: true },
                { dataField: "sort", caption: "Сортировка", width: "80px", fixed: true, allowEditing: true },
            ];

            // const arr = [];
            const obj = toJS(mainStore.columnMatrixSource[0]);
            const arr = Object.keys(obj).filter(e => Number(e));

            const newArr = arr.map(e => {
                const o = mainStore.statusAdminSource.find(el => el.status_id === Number(e));
                return {
                    dataField: e,
                    caption: o.status_name,
                    color: o.color,
                    width: "65px",
                    headerCellTemplate: headerTemplate
                }
            });

            setColumns([...cols, ...newArr]);
        }
        load();
    }, []);

    const handleOKPassClick = async (data) => {
        await mainStore.changePassword(userId, data.pass);
        setPassEditFormVisible(false);
        setEditStatus("BROWSE");
    }

    const handleOKUserClick = async (data) => {
        if (editStatus === "EDIT")
            await mainStore.updateUser(data);
        else
            await mainStore.insertUser(data);

        setUserEditFormVisible(false);
        setEditStatus("BROWSE");
        await mainStore.getUserSource();
        await mainStore.getMatrixSource();
    }

    const onUserMenuClick = async (e) => {
        switch (e.itemData.code) {
            case "add":
                setEditStatus("INSERT");
                setUserEditFormVisible(true);
                break;
            case "edit":
                setEditStatus("EDIT");
                setUserEditFormVisible(true);
                break;
            case "delete":
                const result = await confirm("Удалить пользователя?");
                if (!result) return;

                // setDeleteUserConfirmVisible(false);

                const res = await mainStore.deleteUser(userId);
                if (res !== "OK") {
                    await alert(res);
                    // setErrorMsg(res);
                    // setErrorVisible(true);
                    return;
                }

                await mainStore.getUserSource();
                await mainStore.getMatrixSource();
                // setDeleteUserConfirmVisible(true);
                break;
            case "chpass":
                setPassEditFormVisible(true);
                break;
            default:
                return;
        }
    }

    const colorCellRender = (data) => {
        return (
            <div style={{ backgroundColor: data.data.color, color: data.data.color }}> {String.fromCharCode(0)} </div>
        );
    }

    const onReorderStatus = async (e) => {
        const visibleRows = e.component.getVisibleRows();
        const newStruct = mainStore.statusAdminSource.map(el => toJS(el));

        const toIndex = newStruct.findIndex(item => item.status_id === visibleRows[e.toIndex].data.status_id);
        const fromIndex = newStruct.findIndex(item => item.status_id === e.itemData.status_id);

        newStruct[fromIndex].order_no = toIndex + 1;
        newStruct[toIndex].order_no = fromIndex + 1;

        newStruct.splice(fromIndex, 1);
        newStruct.splice(toIndex, 0, toJS(e.itemData));

        const promises = [];

        newStruct.forEach((el, i) => {
            el.order_no = i + 1;
            promises.push(mainStore.updateStatus(el));
        });

        await Promise.all(promises);

        mainStore.setStatusAdminSource(newStruct);
    }

    const onReorderColumn = async (e) => {
        setLoadPanelVisible(true);

        const visibleRows = e.component.getVisibleRows();
        const newStruct = mainStore.columnMatrixSource.map(el => toJS(el));

        const toIndex = newStruct.findIndex(item => item.column_name === visibleRows[e.toIndex].data.column_name);
        const fromIndex = newStruct.findIndex(item => item.column_name === e.itemData.column_name);

        newStruct[fromIndex].order_no = toIndex + 1;
        newStruct[toIndex].order_no = fromIndex + 1;

        newStruct.splice(fromIndex, 1);
        newStruct.splice(toIndex, 0, toJS(e.itemData));

        const promises = [];

        newStruct.forEach((el, i) => {
            el.order_no = i + 1;
            promises.push(mainStore.updateColumnOrder({ columnName: el.column_name, orderNo: el.order_no }));
        });

        await Promise.all(promises);

        mainStore.setColumnMatrixSource(newStruct);
        
        setLoadPanelVisible(false);
    }

    const handleOKStatusClick = async (data) => {
        let id;

        if (editStatus === "EDIT") {
            const obj = { ...mainStore.statusAdminSource.find(e => e.status_id === statusId) };
            obj.status_name = data.statusName;
            obj.color = data.color;

            await mainStore.updateStatus(obj);
        }
        else {
            id = await mainStore.insertStatus(data);
        }

        await mainStore.getStatusAdminSource();

        if (id)
            setStatusId(id);

        setStatusEditFormVisible(false);
        setEditStatus("BROWSE");
    }

    const createMatrixColumns = (e) => {
        e.component.beginUpdate();

        const cols = e.component.getVisibleColumns();

        for (let i = cols.length - 1; i > 0; i--) {
            e.component.deleteColumn(i);
        }

        const arr = [...Object.keys(mainStore.matrixSource[0])];
        for (let i = 1; i <= 4; i++)
            arr.pop();

        arr.forEach((c) => {
            const obj = mainStore.userSource.find(u => u.user_id === Number(c));
            if (obj) {
                if (!cols.find(col => col.dataField === c))
                    e.component.addColumn({ dataField: c, caption: obj.user_name, width: 140 });
            }
        });

        if (!cols.find(col => col.dataField === " "))
            e.component.addColumn({ dataField: " ", allowEditing: false });

        e.component.endUpdate();
    }

    const saveMatrix = async (e) => {
        for (let i = 0; i < e.changes.length; i++) {
            const statusId = e.changes[i].key;
            const data = toJS(e.changes[i].data);
            // console.log(data);

            for (let k = 0; k < Object.keys(data).length; k++) {
                const userId = Number(Object.keys(data)[k]);
                if (isNaN(userId)) continue;

                const checked = Object.values(data)[k];

                await mainStore.updateMatrix({
                    statusId: statusId,
                    userId: userId,
                    checked: checked
                });
            }
        }
    }

    const statusCellRender = (data) => {
        const color = data.data.color;
        return <div style={{ color: color, padding: "0px" }}> {data.displayValue} </div>
    }

    const checkAdminRender = (data) => {
        return (
            <Checkbox checked={data.data.isadmin} disabled />
        );
    }

    const checkActiveRender = (data) => {
        return (
            <Checkbox checked={data.data.isactive} disabled />
        );
    }

    const handleDeleteStatus = async () => {
        const result = await confirm("Удалить статус?");
        if (!result) return;

        const res = await mainStore.deleteStatus(statusId);
        if (res !== "OK") {
            await alert(res);
            return;
        }

        await mainStore.getStatusAdminSource();
    }

    return (
        <>
            <PasswordEditForm
                visible={passEditFormVisible}
                onOkClick={handleOKPassClick}
                editStatus={editStatus}
                userId={editStatus === "EDIT" ? userId : null}
                onCancelClick={() => { setPassEditFormVisible(false); setEditStatus("BROWSE"); }}
            />

            <LoadPanel
                visible={loadPanelVisible}
                showPane
                shading
                shadingColor="rgba(0,0,0,0.4)"
                message="Загрузка..."
                onHiding={() => setLoadPanelVisible(false)}
            />

            {
                userEditFormVisible
                    ?
                    <UserEditForm
                        visible={userEditFormVisible}
                        editStatus={editStatus}
                        userId={editStatus === "EDIT" ? userId : null}
                        onOkClick={handleOKUserClick}
                        onCancelClick={() => { setUserEditFormVisible(false); setEditStatus("BROWSE"); }}
                    />
                    :
                    null
            }

            {
                statusEditFormVisible
                    ?
                    <StatusEditForm
                        visible={statusEditFormVisible}
                        onOkClick={handleOKStatusClick}
                        editStatus={editStatus}
                        statusId={editStatus === "EDIT" ? statusId : null}
                        onCancelClick={() => { setStatusEditFormVisible(false); setEditStatus("BROWSE"); }}
                    />
                    :
                    null
            }

            <ContextMenu
                dataSource={[
                    { text: "Добавить пользователя", code: "add", icon: "add" },
                    { text: "Изменить пользователя", code: "edit", icon: "edit", disabled: !userId },
                    { text: "Удалить пользователя", code: "delete", icon: "remove", disabled: !userId },
                    { text: "Сменить пароль", code: "chpass", icon: "key", disabled: userId === undefined, beginGroup: true },
                ]}
                target="#userGrid"
                onItemClick={onUserMenuClick}
            />

            <div style={{ height: "100%" }}>
                {
                    mainStore.user.isadmin
                        ?
                        <div style={{ height: "94%" }}>
                            <Toolbar>
                                <FontAwesomeIcon icon={faUserCog} style={{ height: "25px", width: "25px", marginRight: "10px" }} />
                                <div> Администрирование </div>

                                <div style={{ flexGrow: 1 }}></div>

                                <i className="fa fa-home" aria-hidden="true" style={{ fontSize: "20px", height: "20px", color: "#0a3a62" }} />
                                <Link to="/" style={{ margin: "0 5px 0 5px", fontSize: "14px", color: "#0a3a62" }}> На главную </Link>
                                {/* </div> */}
                            </Toolbar>

                            <TabPanel iconPosition="start"
                                height="100%"
                            >
                                <Item title="Пользователи" icon="user">
                                    <DataGrid
                                        id="userGrid"
                                        height="100%"
                                        width="720px"
                                        keyExpr="user_id"
                                        dataSource={mainStore.userSource}
                                        focusedRowKey={userId}
                                        onFocusedRowChanged={(e) => setUserId(e.row?.key)}
                                        focusedRowEnabled
                                        showRowLines
                                        showColumnLines
                                        showBorders
                                        onRowDblClick={() => {
                                            setEditStatus("EDIT");
                                            setUserEditFormVisible(true);
                                        }}
                                    >
                                        <Paging enabled={false} />

                                        <Column dataField="user_name" caption="Имя пользователя" width="260px" />
                                        <Column dataField="login" caption="Логин" width="150px" />
                                        <Column dataField="isadmin" caption="Администратор" dataType="boolean" width="150px" cellRender={checkAdminRender} />
                                        <Column dataField="isactive" caption="Активен" dataType="boolean" width="150px" cellRender={checkActiveRender} />
                                    </DataGrid>
                                </Item>

                                <Item title="Статусы" icon="sun">
                                    <DataGrid
                                        width="720px"
                                        height="100%"
                                        keyExpr="status_id"
                                        dataSource={mainStore.statusAdminSource}
                                        columnAutoWidth
                                        showBorders
                                        showColumnLines
                                        showRowLines
                                        focusedRowKey={statusId}
                                        onFocusedRowChanged={(e) => setStatusId(e.row?.key)}
                                        focusedRowEnabled
                                        allowColumnResizing
                                        onRowDblClick={() => { setEditStatus("EDIT"); setStatusEditFormVisible(true) }}
                                        onContextMenuPreparing={(e) => {
                                            if (!e.items) e.items = [];
                                            e.items.push({ text: "Добавить статус", icon: "add", onItemClick: () => { setEditStatus("INSERT"); setStatusEditFormVisible(true) } });
                                            e.items.push({ text: "Изменить статус", icon: "edit", disabled: !statusId, onItemClick: () => { setEditStatus("EDIT"); setStatusEditFormVisible(true) } });
                                            e.items.push({ text: "Удалить статус", icon: "remove", disabled: !statusId, onItemClick: handleDeleteStatus });
                                        }}
                                    >
                                        <Sorting mode="none" />
                                        <RowDragging allowReordering showDragIcons onReorder={onReorderStatus} />

                                        <Column dataField="status_name" caption="Название статуса" />
                                        <Column dataField="color" caption="Цвет" cellRender={colorCellRender} customizeText={e => ' '} />
                                        <Column dataField="order_no" caption="№ п/п" />
                                    </DataGrid>
                                </Item>

                                <Item title="Права доступа" icon="key">
                                    <DataGrid
                                        ref={matrixGrid}
                                        keyExpr="status_id"
                                        width="100%"
                                        height="100%"
                                        columnAutoWidth
                                        dataSource={mainStore.matrixSource}
                                        onInitialized={createMatrixColumns}
                                        showBorders
                                        showColumnLines
                                        showRowLines
                                        onSaved={saveMatrix}
                                    >
                                        <Column dataField="status_name" caption="Название статуса" width={310} allowEditing={false} cellRender={statusCellRender} />

                                        <Sorting mode="none" />
                                        <Editing allowUpdating mode="batch" />
                                    </DataGrid>
                                </Item>

                                <Item title="Столбцы" icon="fieldchooser">
                                    <DataGrid
                                        dataSource={mainStore.columnMatrixSource}
                                        height="100%"
                                        showBorders
                                        showColumnLines
                                        showRowLines
                                        columns={columns}
                                        onSaved={async (e) => {
                                            setLoadPanelVisible(true);

                                            const arr = [];

                                            for (let i = 0; i < e.changes.length; i++) {
                                                const data = toJS(e.changes[i].data);

                                                const statuses = Object.keys(data).filter(el => Number(el)).map(v => {
                                                    return {
                                                        status_id: Number(v),
                                                        value: data[v]
                                                    };
                                                });

                                                arr.push({
                                                    column_name: data.column_name,
                                                    editable: data.editable,
                                                    statuses: statuses,
                                                    width: data.width,
                                                    sort: data.sort
                                                });
                                            }
                                            // console.log(arr);
                                            await mainStore.updateColumnMatrix(arr);

                                            setLoadPanelVisible(false);
                                        }}
                                    >
                                        <Scrolling mode="standard" useNative />
                                        <Sorting mode="none" />
                                        <Pager visible={false} />
                                        <Paging defaultPageSize={50} />
                                        <RowDragging allowReordering showDragIcons onReorder={onReorderColumn} />
                                        <Editing allowUpdating mode="batch" />
                                    </DataGrid>
                                </Item>

                                {/* <Item title="Шаблоны" icon="textdocument">

                                </Item> */}
                            </TabPanel>
                        </div>
                        :
                        <div> Администрирование недоступно! </div>
                }
            </div>
        </>
    );
}

export default observer(Admin);