import axios from "axios";

class HTTP {
    get = async (url) => {
        const arr = await axios.get(url);
        return arr.data;
    }

    post = async (url, data) => {
        const res = await axios.post(url, data);
        return res;
    }

    put = async (url, data) => {
        try {
            await axios.put(url, data);
        }
        catch (err) {
            return err.response.data.message;
        }

        return "OK";
    }

    delete = async (url, data) => {
        try {
            await axios.delete(url, {data: data});
        } catch (err) {
            return err.response.data.message;
        }

        return "OK";
    }
}

const http = new HTTP();

export default http;