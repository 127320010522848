import Modal from 'react-bootstrap/Modal';
import { TextBox } from 'devextreme-react';
import { Checkbox } from '@blueprintjs/core';
import { Row } from 'react-bootstrap';
import styled from "styled-components";
import notify from "devextreme/ui/notify"
import { useState, useEffect } from 'react';
import { Button85, MyModalHeader, MyModalTitle } from './dialogs';
import mainStore from './store/mainStore';

const UserEditForm = (props) => {
    const { visible, onOkClick, onCancelClick, editStatus, userId } = props;
    const [show, setShow] = useState(visible);
    const [userName, setUserName] = useState();
    const [login, setLogin] = useState();
    const [active, setActive] = useState(true);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        setShow(visible);
    }, [visible]);

    useEffect(() => {
        if (editStatus !== "EDIT") {
            setUserName(null);
            setActive(true);
            setAdmin(false);
            setLogin(null);
            return;
        };

        const u = mainStore.userSource.find(e => e.user_id === userId);
        setUserName(u?.user_name);
        setActive(u?.isactive);
        setAdmin(u?.isadmin);
        setLogin(u?.login);
    }, [editStatus, userId]);

    const handleOkClick = () => {
        if (!userName || !login) {
            notify({ message: 'Не заполнены обязательные поля!', position: 'center', width: 300 }, "error", 2000);
            return;
        }
        setShow(false);

        onOkClick({
            userId: userId,
            userName: userName,
            login: login,
            active: active,
            admin: admin
        });
    }

    return (
        <Modal show={show} centered onHide={() => { setShow(false); onCancelClick(); }}>
            <MyModalHeader closeButton>
                <MyModalTitle> {editStatus === "INSERT" ? "Новый пользователь" : "Изменить пользователя"} </MyModalTitle>
            </MyModalHeader>

            <Modal.Body>
                <Row>
                    <RowDiv>
                        <div> Имя пользователя: </div>
                        <TextBox value={userName} onValueChanged={e => setUserName(e.value)}></TextBox>
                    </RowDiv>
                    <RowDiv>
                        <div> Логин: </div>
                        <TextBox value={login} onValueChanged={e => setLogin(e.value)}></TextBox>
                    </RowDiv>
                    <RowDiv style={{marginTop: "4px"}}>
                        <Checkbox checked={active} onChange={e => setActive(e.target.checked)}> Активен </Checkbox>
                    {/* </RowDiv> */}
                    {/* <RowDiv style={{marginTop: "4px"}}> */}
                        <Checkbox checked={admin} onChange={e => setAdmin(e.target.checked)}> Администратор </Checkbox>
                    </RowDiv>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button85 variant="primary" onClick={handleOkClick} disabled={!userName || !login}>
                    OK
                </Button85>
                <Button85 variant="secondary" onClick={() => {
                    setShow(false);
                    onCancelClick();
                }}>
                    Отмена
                </Button85>
            </Modal.Footer>
        </Modal>
    );
}

const RowDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

export default UserEditForm;